@import "../../../assets/styles/sass/variables/variable";

.routesContainer {
  padding: 18px 16px;
  width: 100%;
  height: 100%;
  // overflow-y: auto;
  &.private {
    padding-left: calc(96px + 16px);
    background-color: $grey-50;
  }
}
