@import '../../../assets/styles/sass/variables/variable';

.load_container {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    overflow: hidden;
    letter-spacing: normal;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;

    &.global {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 9999;
    }

    .moderspinner{
        width: 100px;
        height: 100px;
        border: dotted 12px $primary-700;
        border-radius: 100%;
        animation: spin 1s linear infinite;
      }
      
      
      
      
      
      .dots_circle_spinner {
        display: inline-block;
        height: 1em;
        width: 1em;
        // font-size: 6em;
        // color: $primary-700;
        line-height: 1;
        vertical-align: middle;
        border-radius: 1em;
        transition: all 150ms linear 0s;
        transform: scale(0);
        opacity: 0;
        box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
      }
      .dots_circle_spinner.loading {
        transform: scale(0.25);
        // color: #87AA96;
        opacity: 1;
        -webkit-animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation, 4s linear  normal infinite forwards running spin;
                animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation, 4s linear  normal infinite forwards running spin;
      }
      @keyframes spin {
        0% { 
          transform: rotate(0deg) ;
        }
        50% { transform: rotate(180deg) ;
        }
        100% { transform: rotate(360deg) ;
        }
      }
      @-webkit-keyframes dots-circle-rotation {
        100% {
          box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
          color: $primary-700;
        }
        87.5% {
          box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
        }
        75% {
          box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
        //   color: $primary-700;
        }
        62.5% {
          box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
        }
        50% {
          box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em, -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em, 1.41421356em -1.41421356em 0 -0.3125em;
          color: #587464;
        }
        37.5% {
          box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
        }
        25% {
          box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em, -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em, 1.41421356em -1.41421356em 0 -0.1875em;
        }
        12.5% {
          box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
        }
        0% {
          box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
          color: #87AA96;
        }
      }
      @keyframes dots-circle-rotation {
        100% {
          box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
          color: $primary-700;
        }
        87.5% {
          box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
        }
        75% {
          box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
          
        }
        62.5% {
          box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
        }
        50% {
          box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em, -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em, 1.41421356em -1.41421356em 0 -0.3125em;
          color: #587464;
        }
        37.5% {
          box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
        }
        25% {
          box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em, -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em, 1.41421356em -1.41421356em 0 -0.1875em;
          
        }
        12.5% {
          box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
        }
        0% {
          box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
          color: #87AA96;
        }
      }
}