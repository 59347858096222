@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;500;600;700;800;900&family=Poppins:wght@200;300;400;500;600&display=swap");

@import "../src/assets/styles/sass/main.scss";
@import "react-datepicker/dist/react-datepicker.css";
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


html {
  box-sizing: border-box;
  /* 10px */

  font-size: 62.5%;
  scroll-behavior: smooth;
}

.cursor-pointer {
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 5px !important;
  height: 5px !important;
  background-color: transparent !important;
  z-index: 10;
}
::-webkit-scrollbar-thumb {
  width: 5px !important;
  height: 5px !important;
  background-color: $primary-default !important;
  z-index: 10;
}

body {
  /* font-family: 'Noto Sans', sans-serif !important; */
  font-family: $font-secondary;
  font-size: 16px;
}
h1,
h2,
h3,
h4 {
  font-family: $font-primary;
  font-weight: 700;
}

/*custom common classes*/

.two_child_wrapper {
  display: flex;
  gap: 16px;
}

a {
  text-decoration: none;
}

.quill {
  overflow: hidden;
  height: em(468.5);
  border-bottom: solid 1px $grey-300;
}

.react-datepicker {
  font-family: $font-secondary;
  font-size: 1rem;
  background-color: $white;
  color: $grey-700;
  border: 1px solid $grey-300;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker__input-container {
  input {
    font-size: 14px;
    height: 45px;
    width: 100%;
    padding: 10px;
    border: 1px solid $grey-300;
    &::placeholder {
      color: $grey-300;
    }
  }
}

.react-datepicker__input-container {
  width: inherit;
}
.react-datepicker__tab-loop {
  position: absolute;
  z-index: 2000;
  top: 0;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker {
  font-size: 1em;
}
.react-datepicker__header {
  padding-top: 0.8em;
  background-color: $white;
  border-bottom: 1px solid #aeaeae;
}
.react-datepicker__month {
  margin: 0.4em 1em;
}
.react-datepicker__day-name,
.react-datepicker__day {
  width: 1.9em;
  line-height: 1.9em;
  margin: 0.166em;
}
.react-datepicker__current-month {
  font-size: 1em;
}
.react-datepicker__navigation {
  top: 1em;
  line-height: 1.7em;
  // border: 0.45em solid transparent;
}
.react-datepicker__navigation--previous {
  // border-right-color: #ccc;
  left: 1em;
}
.react-datepicker__navigation--next {
  // border-left-color: #ccc;
  right: 1em;
}
.font_12 {
  font-size: 12px;
}

.font_14 {
  font-size: 14px;
}
.font_16 {
  font-size: 16px;
}
.font_18 {
  font-size: 18px;
}
.font_20 {
  font-size: 20px;
}
.font_wt_100 {
  font-weight: 100;
}
.font_wt_400 {
  font-weight: 400;
}
.font_wt_500 {
  font-weight: 500;
}
.font_wt_600 {
  font-weight: 600;
}

.font_primary {
  font-family: $font-primary !important;
}
.font_secondary {
  font-family: $font-secondary !important;
}
// .MuiButtonBase-root.MuiPickersDay-root, .MuiDayPicker-weekDayLabel.css-nnlf0r-MuiTypography-root-MuiDayPicker-weekDayLabel, .MuiPickersCalendarHeader-label.css-dplwbx-MuiPickersCalendarHeader-label{
//   font-size: 12px;
// }
.MuiInputBase-root.MuiOutlinedInput-root {
  border-radius: 0;
}
.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline {
  background-color: rgba(230, 230, 230, 0.3);
}
.ruler {
  border-top: 1px solid $grey-300;
  margin: 0.5rem 0;
}
.no_wrap {
  flex-wrap: nowrap;
}
.overflow_y_auto {
  overflow-y: auto;
}
.pos-relative {
  position: relative;
}
.one_child_wrapper {
  width: calc(50% - 10px);
}

.MuiOutlinedInput-root {
  &.Mui-focused fieldset {
    border-color: $primary-default !important;
  }
  &.Mui-error fieldset {
    border-color: $warning-default !important;
  }
}

.MuiDataGrid-iconButtonContainer {
  visibility: visible !important;
  width: auto !important;
}
